[
	[
		"EW16-NE3-TE17",
		0,
		0,
		229,
		40
	],
	[
		"NS27-CE2-TE20",
		229,
		0,
		223,
		40
	],
	[
		"NS24-NE6-CC1",
		452,
		0,
		214,
		40
	],
	[
		"EW21-CC22",
		0,
		40,
		168,
		40
	],
	[
		"NS25-EW13",
		168,
		40,
		167,
		40
	],
	[
		"NS26-EW14",
		335,
		40,
		167,
		40
	],
	[
		"EW12-DT14",
		502,
		40,
		166,
		40
	],
	[
		"NE12-CC13",
		0,
		80,
		163,
		40
	],
	[
		"NS17-CC15",
		163,
		80,
		163,
		40
	],
	[
		"CC10-DT26",
		326,
		80,
		162,
		40
	],
	[
		"NS21-DT11",
		666,
		0,
		159,
		40
	],
	[
		"NS22-TE14",
		488,
		80,
		159,
		40
	],
	[
		"DT10-TE11",
		668,
		40,
		156,
		40
	],
	[
		"NS1-EW24",
		647,
		80,
		154,
		40
	],
	[
		"EW2-DT32",
		0,
		120,
		152,
		40
	],
	[
		"DT35-CG1",
		0,
		160,
		150,
		40
	],
	[
		"NE1-CC29",
		0,
		200,
		150,
		40
	],
	[
		"CC4-DT15",
		0,
		240,
		148,
		40
	],
	[
		"CC19-DT9",
		0,
		280,
		148,
		40
	],
	[
		"CC17-TE9",
		0,
		320,
		147,
		40
	],
	[
		"DT16-CE1",
		0,
		360,
		147,
		40
	],
	[
		"NE4-DT19",
		0,
		400,
		147,
		40
	],
	[
		"NE7-DT12",
		0,
		440,
		147,
		40
	],
	[
		"NE16-STC",
		0,
		480,
		147,
		40
	],
	[
		"NE17-PTC",
		0,
		520,
		147,
		40
	],
	[
		"EW8-CC9",
		0,
		560,
		142,
		40
	],
	[
		"NS4-BP1",
		0,
		600,
		135,
		40
	],
	[
		"DT1-BP6",
		0,
		640,
		133,
		40
	],
	[
		"NS9-TE2",
		0,
		680,
		132,
		40
	],
	[
		"EW4-CG",
		0,
		720,
		127,
		40
	],
	[
		"EW10",
		0,
		760,
		90,
		40
	],
	[
		"EW15",
		152,
		120,
		90,
		40
	],
	[
		"EW17",
		242,
		120,
		90,
		40
	],
	[
		"EW18",
		332,
		120,
		90,
		40
	],
	[
		"EW19",
		422,
		120,
		90,
		40
	],
	[
		"EW20",
		512,
		120,
		90,
		40
	],
	[
		"EW22",
		602,
		120,
		90,
		40
	],
	[
		"EW23",
		692,
		120,
		90,
		40
	],
	[
		"EW25",
		152,
		160,
		90,
		40
	],
	[
		"EW26",
		242,
		160,
		90,
		40
	],
	[
		"EW27",
		332,
		160,
		90,
		40
	],
	[
		"EW28",
		422,
		160,
		90,
		40
	],
	[
		"EW29",
		512,
		160,
		90,
		40
	],
	[
		"EW30",
		602,
		160,
		90,
		40
	],
	[
		"EW31",
		692,
		160,
		90,
		40
	],
	[
		"EW32",
		152,
		200,
		90,
		40
	],
	[
		"EW33",
		242,
		200,
		90,
		40
	],
	[
		"EW11",
		332,
		200,
		88,
		40
	],
	[
		"CC12",
		420,
		200,
		86,
		40
	],
	[
		"CC14",
		506,
		200,
		86,
		40
	],
	[
		"CC16",
		592,
		200,
		86,
		40
	],
	[
		"CC20",
		678,
		200,
		86,
		40
	],
	[
		"CC21",
		152,
		240,
		86,
		40
	],
	[
		"CC24",
		152,
		280,
		86,
		40
	],
	[
		"CC23",
		152,
		320,
		86,
		40
	],
	[
		"CC25",
		152,
		360,
		86,
		40
	],
	[
		"CC26",
		152,
		400,
		86,
		40
	],
	[
		"CC28",
		152,
		440,
		86,
		40
	],
	[
		"CC27",
		152,
		480,
		86,
		40
	],
	[
		"BP10",
		152,
		520,
		84,
		40
	],
	[
		"BP12",
		152,
		560,
		84,
		40
	],
	[
		"BP13",
		152,
		600,
		84,
		40
	],
	[
		"CC11",
		152,
		640,
		84,
		40
	],
	[
		"NE10",
		152,
		680,
		84,
		40
	],
	[
		"NE13",
		152,
		720,
		84,
		40
	],
	[
		"NE14",
		152,
		760,
		84,
		40
	],
	[
		"NE15",
		238,
		240,
		84,
		40
	],
	[
		"NS10",
		322,
		240,
		84,
		40
	],
	[
		"NS12",
		406,
		240,
		84,
		40
	],
	[
		"NS13",
		490,
		240,
		84,
		40
	],
	[
		"NS14",
		574,
		240,
		84,
		40
	],
	[
		"NS15",
		658,
		240,
		84,
		40
	],
	[
		"NS16",
		238,
		280,
		84,
		40
	],
	[
		"NS18",
		322,
		280,
		84,
		40
	],
	[
		"NS19",
		406,
		280,
		84,
		40
	],
	[
		"NS20",
		490,
		280,
		84,
		40
	],
	[
		"NS23",
		574,
		280,
		84,
		40
	],
	[
		"NS28",
		658,
		280,
		84,
		40
	],
	[
		"BP11",
		742,
		240,
		83,
		40
	],
	[
		"DT13",
		742,
		280,
		83,
		40
	],
	[
		"DT17",
		238,
		320,
		83,
		40
	],
	[
		"DT18",
		238,
		360,
		83,
		40
	],
	[
		"DT20",
		238,
		400,
		83,
		40
	],
	[
		"DT22",
		238,
		440,
		83,
		40
	],
	[
		"DT21",
		238,
		480,
		83,
		40
	],
	[
		"DT23",
		238,
		520,
		83,
		40
	],
	[
		"DT25",
		238,
		560,
		83,
		40
	],
	[
		"DT24",
		238,
		600,
		83,
		40
	],
	[
		"DT27",
		238,
		640,
		83,
		40
	],
	[
		"DT29",
		238,
		680,
		83,
		40
	],
	[
		"DT28",
		238,
		720,
		83,
		40
	],
	[
		"DT30",
		238,
		760,
		83,
		40
	],
	[
		"DT31",
		321,
		320,
		83,
		40
	],
	[
		"DT34",
		404,
		320,
		83,
		40
	],
	[
		"DT33",
		487,
		320,
		83,
		40
	],
	[
		"NE11",
		570,
		320,
		83,
		40
	],
	[
		"NS11",
		653,
		320,
		83,
		40
	],
	[
		"TE12",
		736,
		320,
		82,
		40
	],
	[
		"TE13",
		321,
		360,
		82,
		40
	],
	[
		"TE15",
		403,
		360,
		82,
		40
	],
	[
		"TE16",
		485,
		360,
		82,
		40
	],
	[
		"TE18",
		567,
		360,
		82,
		40
	],
	[
		"TE19",
		649,
		360,
		82,
		40
	],
	[
		"TE22",
		731,
		360,
		82,
		40
	],
	[
		"EW1",
		321,
		400,
		76,
		40
	],
	[
		"EW3",
		321,
		440,
		76,
		40
	],
	[
		"EW5",
		321,
		480,
		76,
		40
	],
	[
		"EW6",
		321,
		520,
		76,
		40
	],
	[
		"EW7",
		321,
		560,
		76,
		40
	],
	[
		"EW9",
		321,
		600,
		76,
		40
	],
	[
		"PW1",
		321,
		640,
		76,
		40
	],
	[
		"PW2",
		321,
		680,
		76,
		40
	],
	[
		"PW3",
		321,
		720,
		76,
		40
	],
	[
		"PW4",
		321,
		760,
		76,
		40
	],
	[
		"PW5",
		397,
		400,
		76,
		40
	],
	[
		"PW6",
		473,
		400,
		76,
		40
	],
	[
		"PW7",
		549,
		400,
		76,
		40
	],
	[
		"SW1",
		625,
		400,
		76,
		40
	],
	[
		"SW2",
		701,
		400,
		76,
		40
	],
	[
		"SW3",
		397,
		440,
		76,
		40
	],
	[
		"SW4",
		397,
		480,
		76,
		40
	],
	[
		"SW5",
		397,
		520,
		76,
		40
	],
	[
		"SW6",
		397,
		560,
		76,
		40
	],
	[
		"SW7",
		397,
		600,
		76,
		40
	],
	[
		"SW8",
		397,
		640,
		76,
		40
	],
	[
		"CG2",
		397,
		680,
		74,
		40
	],
	[
		"CC2",
		397,
		720,
		72,
		40
	],
	[
		"CC5",
		397,
		760,
		72,
		40
	],
	[
		"CC3",
		473,
		440,
		72,
		40
	],
	[
		"CC6",
		545,
		440,
		72,
		40
	],
	[
		"CC7",
		617,
		440,
		72,
		40
	],
	[
		"CC8",
		689,
		440,
		72,
		40
	],
	[
		"BP2",
		473,
		480,
		71,
		40
	],
	[
		"BP3",
		544,
		480,
		71,
		40
	],
	[
		"BP4",
		615,
		480,
		71,
		40
	],
	[
		"BP5",
		686,
		480,
		71,
		40
	],
	[
		"BP7",
		473,
		520,
		71,
		40
	],
	[
		"BP8",
		473,
		560,
		71,
		40
	],
	[
		"BP9",
		473,
		600,
		71,
		40
	],
	[
		"NE5",
		473,
		640,
		71,
		40
	],
	[
		"NE8",
		473,
		680,
		71,
		40
	],
	[
		"NE9",
		473,
		720,
		71,
		40
	],
	[
		"NS2",
		473,
		760,
		71,
		40
	],
	[
		"NS3",
		544,
		520,
		71,
		40
	],
	[
		"NS5",
		615,
		520,
		71,
		40
	],
	[
		"NS7",
		686,
		520,
		71,
		40
	],
	[
		"NS8",
		544,
		560,
		71,
		40
	],
	[
		"DT3",
		615,
		560,
		70,
		40
	],
	[
		"DT2",
		685,
		560,
		70,
		40
	],
	[
		"DT5",
		755,
		560,
		70,
		40
	],
	[
		"DT6",
		544,
		600,
		70,
		40
	],
	[
		"DT7",
		544,
		640,
		70,
		40
	],
	[
		"DT8",
		544,
		680,
		70,
		40
	],
	[
		"PE1",
		544,
		720,
		70,
		40
	],
	[
		"PE2",
		544,
		760,
		70,
		40
	],
	[
		"PE3",
		614,
		600,
		70,
		40
	],
	[
		"PE4",
		684,
		600,
		70,
		40
	],
	[
		"PE5",
		754,
		600,
		70,
		40
	],
	[
		"PE6",
		614,
		640,
		70,
		40
	],
	[
		"PE7",
		684,
		640,
		70,
		40
	],
	[
		"SE1",
		754,
		640,
		70,
		40
	],
	[
		"SE2",
		614,
		680,
		70,
		40
	],
	[
		"SE3",
		614,
		720,
		70,
		40
	],
	[
		"SE4",
		614,
		760,
		70,
		40
	],
	[
		"SE5",
		684,
		680,
		70,
		40
	],
	[
		"TE1",
		757,
		480,
		68,
		40
	],
	[
		"TE3",
		757,
		520,
		68,
		40
	],
	[
		"TE4",
		754,
		680,
		68,
		40
	],
	[
		"TE5",
		684,
		720,
		68,
		40
	],
	[
		"TE6",
		684,
		760,
		68,
		40
	],
	[
		"TE7",
		752,
		720,
		68,
		40
	],
	[
		"TE8",
		752,
		760,
		68,
		40
	],
	[
		"green-purple-brown",
		0,
		800,
		50,
		16
	],
	[
		"red-purple-yellow",
		90,
		760,
		50,
		16
	],
	[
		"red-yellow-brown",
		90,
		776,
		50,
		16
	],
	[
		"blue-brown",
		50,
		800,
		36,
		16
	],
	[
		"blue-gray",
		90,
		792,
		36,
		16
	],
	[
		"blue-green",
		90,
		808,
		36,
		16
	],
	[
		"blue-yellow",
		782,
		120,
		36,
		16
	],
	[
		"green-blue",
		782,
		136,
		36,
		16
	],
	[
		"green-green",
		782,
		160,
		36,
		16
	],
	[
		"green-yellow",
		782,
		176,
		36,
		16
	],
	[
		"purple-blue",
		764,
		200,
		36,
		16
	],
	[
		"purple-gray",
		764,
		216,
		36,
		16
	],
	[
		"purple-yellow",
		152,
		800,
		36,
		16
	],
	[
		"red-blue",
		188,
		800,
		36,
		16
	],
	[
		"red-brown",
		238,
		800,
		36,
		16
	],
	[
		"red-gray",
		274,
		800,
		36,
		16
	],
	[
		"red-green",
		321,
		800,
		36,
		16
	],
	[
		"red-yellow",
		357,
		800,
		36,
		16
	],
	[
		"yellow-blue",
		777,
		400,
		36,
		16
	],
	[
		"yellow-brown",
		777,
		416,
		36,
		16
	],
	[
		"blue",
		801,
		80,
		21,
		16
	],
	[
		"brown",
		801,
		96,
		21,
		16
	],
	[
		"gray",
		127,
		720,
		21,
		16
	],
	[
		"green",
		127,
		736,
		21,
		16
	],
	[
		"purple",
		126,
		792,
		21,
		16
	],
	[
		"red",
		126,
		808,
		21,
		16
	],
	[
		"yellow",
		800,
		200,
		21,
		16
	]
]